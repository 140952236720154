<template>
    <div>
        <apexchart v-if="loadchart" :type="type" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loadchart: false,
                type: 'pie',
                series: [1],
                chartOptions: {
                    chart: {
                        type: 'pie',
                        height: 350
                    },
                    theme: {
                        mode: this.$vuetify.theme.dark ? 'dark' : 'light',
                        palette:'palette2'
                    },
                    labels: [],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: 350
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
            }
        },
        mounted() {
            this.loadchart = false;
            this.series = [];
            this.chartOptions.labels = [];
            setTimeout(() => {
                console.log('mounted');
                let chart = this.chart_value
                console.log(chart);
                chart.forEach(element => {
                    this.series.push(
                        parseInt(element.value)
                    )
                    this.chartOptions.labels.push(element.name)
                });

                this.loadchart = true;
            }, 1000);
        },
        props: {
            chart_value: {
                type: Array,
                required: true
            },


        }
    }
</script>