<template>
    <div>
        <apexchart v-if="loadchart" :type="type" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loadchart: false,
                type: 'bar',
                series: [{
                    data: [1]
                }],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 14,
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: true
                    },
                    
                    fill: {
                        type: "gradient",
                        gradient: {
                            gradientToColors: ['#ad5389', '#3c1053'],
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0,90, 100]
                        }
                    },
                    xaxis: {
                        categories: [''],
                    },
                    theme: {
                        mode: this.$vuetify.theme.dark ? 'dark' : 'light',
                    }
                },
            }
        },
        mounted() {
            this.loadchart = false;
            // access props
            // clear the chart
            this.series = [{
                data: []
            }];
            this.chartOptions.xaxis.categories = [];
            setTimeout(() => {
                console.log('mounted');
                let chart = this.chart_value
                console.log(chart);
                chart.forEach(element => {
                    this.series[0].data.push(
                        element.value
                    )
                    this.chartOptions.xaxis.categories.push(element.name)
                });

                this.loadchart = true;
            }, 1000);
        },
        props: {
            chart_value: {
                type: Array,
                required: true
            },
            

        }
    }
</script>