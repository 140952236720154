<template>
    <div>
        <apexchart v-if="loadchart" :type="type" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                loadchart: false,
                type: 'area',
                series: [{
                    data: [1]
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'date',
                        categories: ['']
                    },
                    theme: {
                        mode: this.$vuetify.theme.dark ? 'dark' : 'light',
                        palette:'palette10'
                    },
                    tooltip: {
                        x: {
                            format: 'dd-MM-yyyy'
                        },
                    },
                },
            }
        },
        mounted() {
            this.loadchart = false;
            // access props
            // clear the chart
            this.series = [{
                data: []
            }];
            this.chartOptions.xaxis.categories = [];
            setTimeout(() => {
                console.log('mounted');
                let chart = this.chart_value
                chart.forEach(element => {
                    this.series[0].data.push(
                        element.value
                    )
                    this.chartOptions.xaxis.categories.push(element.name)
                });

                this.loadchart = true;
            }, 1000);
        },
        props: {
            chart_value: {
                type: Array,
                required: true
            },


        }
    }
</script>