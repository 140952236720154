<template>
  <div>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 lg4>
          <v-card outlined class="ma-1 rounded-lg">
            <v-card-title primary-title>
              Total Users
              <v-spacer></v-spacer>
              {{changeToK(result.users_count , 1)}} ❤️
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 lg4>
          <v-card outlined class="ma-1 rounded-lg">
            <v-card-title primary-title>
              Total Items
              <v-spacer></v-spacer>
              {{changeToK(result.items_count , 1)}} 💎
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs12 lg4>
          <v-card outlined class="ma-1 rounded-lg">
            <v-card-title primary-title>
              Total Reviews
              <v-spacer></v-spacer>
              {{changeToK(result.reviews_count , 1)}} ⭐
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 lg4 md4 xl4>
          <v-card class="ma-1 rounded-lg" style="height:440px">
            <v-card-title primary-title>
              Items by Category
              <v-spacer></v-spacer>
              🖖
            </v-card-title>
            <v-card-text>
              <BarChartVue v-if="result.items_count_by_category" :chart_value="result.items_count_by_category">
              </BarChartVue>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 lg8 xl8 md8>
          <v-card class="ma-1 rounded-lg" style="height:440px">
            <v-card-title primary-title>
              Recently Joined Users (last 30 days)
              <v-spacer></v-spacer>
              🤓
            </v-card-title>
            <v-card-text>
              <RecentUsers class="" v-if="result.users_count_by_day" :chart_value="result.users_count_by_day">
              </RecentUsers>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 lg6 md6 xl6>
          <v-card class="ma-1 rounded-lg" style="height:440px">
            <v-card-title primary-title>
              Items by City
              <v-spacer></v-spacer>
              🗺️
            </v-card-title>
            <v-card-text>
              <ItemsByCityChart class="" v-if="result.items_count_by_city" :chart_value="result.items_count_by_city">
              </ItemsByCityChart>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 lg6 md6 xl6>
          <v-card class="ma-1 rounded-lg" style="height:440px">
            <v-card-title primary-title>
              Last Activities
              <v-spacer></v-spacer>
              📅
            </v-card-title>
            <v-card-text>
              <v-list three-line v-if="result.last_comments">
                  <v-list-item  v-for="(item, index) in result.last_comments" :key="index">
                    <v-list-item-avatar>
                      <v-img :src="item.user_profile_picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h4 class="view-page">{{item.user_display_name}}</h4>
                      </v-list-item-title>
                      <v-list-item-subtitle class="view-page blue--text" v-html="item.item_name"></v-list-item-subtitle>
                      <v-list-item-subtitle class="view-page" v-html="item.comment_text"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      {{item.comment_date}}
                    </v-list-item-action>
                  </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
  import axios from 'axios'
  import BarChartVue from '../components/BarChart.vue';
  import ItemsByCityChart from '../components/ItemsByCityChart.vue';
  import RecentUsers from '../components/RecentUsers.vue';
  export default {
    components: {
      BarChartVue,
      ItemsByCityChart,
      RecentUsers
    },
    data() {
      return {
        result: {
          users_count: 0,
          items_count: 0,
          reviews_count: 0,
        }
      }
    },
    methods: {
      changeToK(n, d) {
        if (n < 1000) {
          return n;
        }
        let x = ('' + n).length,
          p = Math.pow
        d = p(10, d)
        x -= x % 3
        return Math.round(n * d / p(10, x)) / d + " kMGTPE" [x / 3]
      },
      getData() {
        axios.get('/charts/home.php')
          .then(r => {
            this.result = r.data

          })
          .catch(e => {
            console.log(e);
          })
      }
    },
    mounted() {
      this.getData()
    }
  }
</script>